import React, { ReactNode, useEffect, useState } from 'react'
import cn from 'classnames'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'

import { Button } from 'ui/button'
import { Checkbox } from 'ui/checkbox'
import { Input } from 'ui/text'

import { StatusMessage } from 'components/status-message'
import { WithScript } from 'components/with-script'

import useAuth from 'lib/hooks/useAuth'
import useTranslation from 'lib/hooks/useTranslation'

import { logError, getError } from 'lib/utils'
import { getDsUserId } from 'lib/utils/auth'
import { useFingerPrintContext } from 'lib/context/fingerprint-context'
import { useAppData } from 'lib/context/app-data-context'

import { EVENTS } from 'lib/constants/events'
import { EMAIL_REGEX, HEADER_REFERRAL_CODE } from 'lib/constants'

import { CUSTOMER_ONBOARDING } from 'gql/auth'

import s from './styles.module.scss'

type FormData = {
  email: string
  marketingEmail: boolean
}

interface ComponentProps {
  variant?: 'default' | 'compact'
  userInfo?: Record<string, any>
  secondaryButton?: ReactNode
  trackEvent: TrackEventType
  trackErrorEvent: TrackErrorEventType
  trackSuccessEvent: TrackSuccessEventType
  onSignUpSuccess: (data: OtpVerifyCustomerData) => void
}

const isDevOrTestEnv = process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development'

interface FieldConfigProps {
  disabled?: boolean
  descriptionText?: string
}

interface FormConfigProps {
  hideHeader?: any
  emailAddress?: FieldConfigProps
  hideMarketingConsent?: boolean
  hideSubmitButton?: boolean
}

const EmailView: React.FC<ComponentProps> = ({
  variant = 'default',
  userInfo,
  secondaryButton,
  trackEvent,
  trackErrorEvent,
  trackSuccessEvent,
  onSignUpSuccess,
}) => {
  const { t } = useTranslation()
  const { setAuthInProgress } = useAuth()
  const { register, formState, getValues, handleSubmit, errors } = useForm<FormData>({ mode: 'onChange' })
  const { getRequestId: getFingerPrintRequestId } = useFingerPrintContext()
  const {
    partnerFeatureControl: { showFeature },
  } = useAppData()
  const { query } = useRouter()
  const { referralCode } = query

  const [signUp, { loading }] = useMutation(CUSTOMER_ONBOARDING.mutation)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const FORM_VARIANTS: Record<string, FormConfigProps> = {
    default: {},
    compact: {
      hideHeader: true,
      emailAddress: {
        disabled: true,
        descriptionText: t('msg.thisEmailWillBeUsedToCreateAccount', { ns: 'auth', number: 8 }),
      },
      hideMarketingConsent: true,
    },
  }

  // Set Variant config value
  const currentVariantConfig = FORM_VARIANTS[variant]

  useEffect(() => {
    setAuthInProgress(loading)
  }, [loading, setAuthInProgress])

  const resetCaptcha = () => {
    if (isDevOrTestEnv) return
    if (window.grecaptcha) window.grecaptcha.reset()
  }

  const trackClickEvent = (signupType: string, marketingEmail?: boolean) => {
    trackEvent({
      attributeId: EVENTS.SIGNUP,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
      signupType,
      marketingEmail,
    })
  }

  const doSignUp = async (captchaToken: string) => {
    if (!captchaToken) return
    const { email, marketingEmail } = getValues()
    trackClickEvent('email', marketingEmail)

    let fingerPrintRequestId

    if (showFeature('fingerprint')) {
      try {
        fingerPrintRequestId = await getFingerPrintRequestId()
      } catch (e) {
        logError(e)
      }
    }

    try {
      setErrorMessage('')
      const response = await signUp({
        variables: {
          email,
          dsUserId: getDsUserId(),
          fpRequestId: fingerPrintRequestId,
        },
        context: {
          headers: {
            [HEADER_REFERRAL_CODE]: referralCode,
          },
        },
      })
      const data = response.data[CUSTOMER_ONBOARDING.name]
      const error = getError(data)
      if (error?.code === 409) {
        setErrorMessage(error?.errorMessage)
      } else if (error?.code >= 400) {
        resetCaptcha()
        setErrorMessage(error?.errorMessage)
        trackErrorEvent(EVENTS.USER_SIGNED_UP, 'signupType', 'email', error?.code, true)
      } else {
        onSignUpSuccess({
          email,
          customerId: data.additionalDetails?.customer_id,
          login: true,
          marketingEmail,
          created: data.additionalDetails?.created,
        })
        trackSuccessEvent(
          EVENTS.USER_SIGNED_UP,
          'signupType',
          'email',
          data.additionalDetails?.customer_id,
          true
        )
      }
    } catch (error: any) {
      resetCaptcha()
      setErrorMessage(error?.message?.replace(/GraphQL error: /gi, '')) // TODO remove replace after 3.0 stable upgrade
      logError(error)
      trackErrorEvent(EVENTS.USER_SIGNED_UP, 'signupType', 'email', error?.code, true)
    }
  }

  const registerCaptchaCallback = () => {
    if (isDevOrTestEnv) return
    if (!window.onCaptchaDone) window.onCaptchaDone = doSignUp
  }

  const onSubmit = () => {
    if (isDevOrTestEnv) doSignUp('fakeCaptchaToken')
    else if (typeof window.grecaptcha?.execute === 'function') window.grecaptcha.execute()
  }

  React.useEffect(
    () => () => {
      delete window.onCaptchaDone
    },
    []
  )

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="email"
        type="email"
        noDescription={!errors.email?.message}
        {...(userInfo?.email && { value: userInfo.email })}
        disabled={currentVariantConfig?.emailAddress?.disabled}
        placeholder={t('t.emailAddress', { ns: 'common' })}
        description={currentVariantConfig?.emailAddress?.descriptionText}
        errorMessage={errors.email?.message}
        getRef={register({
          required: t('msg.thisFieldIsRequired', { ns: 'common' }) as string,
          pattern: {
            value: EMAIL_REGEX,
            message: t('msg.pleaseEnterValidEmail', { ns: 'auth' }),
          },
        })}
        contClassName={cn(s.input, { 'mb-2': !errors.email?.message })}
        descriptionClassName={s.descriptionText}
      />

      {!currentVariantConfig.hideMarketingConsent && (
        <Checkbox
          name="marketingEmail"
          className={s.marketingEmail}
          size="small"
          getRef={register}
          defaultChecked
        >
          {t('msg.receivePromotionsInspirationAndUpdates', { ns: 'common' })}
        </Checkbox>
      )}

      <div className={cn(!!secondaryButton && [s.grid, s.twoColumns, s.reverse])}>
        {errorMessage && (
          <StatusMessage type="error" className={s.error}>
            {errorMessage}
          </StatusMessage>
        )}
        <div>
          <div
            id="recaptcha"
            className="g-recaptcha"
            data-sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_KEY}
            data-callback="onCaptchaDone"
            data-size="invisible"
          />

          <WithScript
            src="//www.google.com/recaptcha/api.js"
            beforeLoaded={registerCaptchaCallback}
            unmountOnExit
            render={({ loaded: scriptLoaded }: any) => (
              <Button
                variant="primary"
                id="create-account-btn"
                type="submit"
                fluid
                disabled={!formState.isValid || !scriptLoaded || loading}
                className={cn({ [s.cta]: variant !== 'compact' })}
                size="medium"
              >
                {t('action.createAccount')}
              </Button>
            )}
          />
        </div>

        {/* Injected secondary buttons received from props */}
        {secondaryButton}
      </div>
    </form>
  )
}

export { EmailView }
