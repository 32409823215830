import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { usePreAuthLocation, useSocialRedirect } from 'lib/hooks/socialAuth'

import KfLogo from 'brand-assets/illustrations/logo/kf-standalone-logo.svg'

import s from '../styles.module.scss'

const KrisFlyerButton: React.FC<AuthButtonProps> = ({
  children,
  iconOnly,
  iconPosition = 'left',
  mode = 'login',
  noIcon = false,
  ...props
}) => {
  const { getAuthorizationData } = useSocialRedirect({ authScreen: mode })
  const { setPreAuthLocation } = usePreAuthLocation()
  const formRef = useRef<HTMLFormElement | null>()

  const [krisflyerAuthData, setKrisflyerAuthData] = useState<any>({ state: {} })

  useEffect(() => {
    async function fetchKrisFlyerAuthData() {
      const data = await getAuthorizationData('KRISFLYER')

      setPreAuthLocation(mode, 'KRISFLYER')

      setKrisflyerAuthData({
        authorizationUrl: data?.authorizationUrl,
        state: JSON.parse(data?.state || '{}'),
      })
    }

    fetchKrisFlyerAuthData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (e: any) => {
    e.preventDefault()

    formRef.current?.submit()
  }

  return (
    <form
      ref={(_ref) => (formRef.current = _ref)}
      method="POST"
      action={krisflyerAuthData?.authorizationUrl}
      className={s.fullWidth}
    >
      {Object.entries(krisflyerAuthData?.state).map(([key, value]) => (
        <input key={key} type="hidden" id={key} name={key} value={value as any} />
      ))}
      {noIcon ? (
        typeof children === 'function' ? (
          children(onSubmit, !krisflyerAuthData?.authorizationUrl)
        ) : (
          children
        )
      ) : (
        <button
          type="button"
          className={cn(s.authButton, { [s['icon-only']]: !!iconOnly }, 'cursor-pointer bold')}
          disabled={!krisflyerAuthData?.authorizationUrl}
          {...props}
          onClick={onSubmit}
        >
          {iconPosition === 'left' && <KfLogo className={s.kfLogo} />}
          {!iconOnly && <span>{children}</span>}
          {iconPosition === 'right' && <KfLogo className={s.kfLogo} />}
        </button>
      )}
    </form>
  )
}

export { KrisFlyerButton }
