import React from 'react'
import cn from 'classnames'

import EmailIcon from 'brand-assets/icon-lib/envelop.svg'

import s from '../styles.module.scss'

const EmailButton: React.FC<AuthButtonProps & { customClassName: string }> = ({ 
  children,
  iconOnly,
  iconPosition = 'left',
  customClassName,
  ...props
}) => (
  <button
    type="button"
    className={cn(customClassName, s.authButton, { [s['icon-only']]: !!iconOnly }, 'cursor-pointer bold')}
    {...props}
  >
    {iconPosition !== 'right' && <EmailIcon />}
    {!iconOnly && <span>{children}</span>}
    {iconPosition === 'right' && <EmailIcon />}
  </button>
)

export { EmailButton }
