import { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import cn from 'classnames'
import { useMutation } from '@apollo/client'

import { Button } from 'ui/button'
import { DrawerDialog } from 'ui/drawer-dialog'

import useTranslation from 'lib/hooks/useTranslation'

import { useAppData } from 'lib/context/app-data-context'
import { useGlobalContext } from 'lib/context/global-context'
import { logError, getError } from 'lib/utils'

import { EVENTS } from 'lib/constants/events'
import { RETRY_OTP_MAX_ATTEMPTS_REACHED } from 'lib/constants'

import { VERIFY_OTP, RESEND_OTP } from 'gql/auth'

import s from './styles.module.scss'

const dummyArray = new Array(6).fill(null)

const VALIDATION_PATTERN = /[0-9]{1}/

const OtpInput = ({ value, onChange, error }: { value: string; onChange: any; error: boolean }) => {
  const { t, join } = useTranslation()
  const placements = useRef<Record<number, string>>({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const element = e.target
    let digit = e.target.value // as string

    if (!VALIDATION_PATTERN.test(digit?.trim?.()) && digit?.trim?.() !== '') return

    // if the didgit is already present in the input, then remove it
    // if 3 was already present in the input, and user entered 2,
    // then remove 3 as the new value will be 23/32 based on where the cursor was
    const oldDigit = placements.current[index]
    if (oldDigit) digit = digit.replace(oldDigit, '')

    placements.current[index] = digit
    const valueArr = value.split('')
    valueArr[index] = digit
    const newVal = valueArr.join('').slice(0, 6)
    onChange(newVal)

    if (digit) {
      const next = element.nextElementSibling as HTMLInputElement
      next?.focus?.()
    }
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const current = e.currentTarget
    if (e.key === 'ArrowLeft' || e.key === 'Backspace') {
      const prev = current.previousElementSibling as HTMLInputElement | null
      prev?.focus()
      prev?.select?.()
      return
    }

    if (e.key === 'ArrowRight') {
      const prev = current.nextSibling as HTMLInputElement | null
      prev?.focus()
      prev?.select?.()
      return
    }
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
    const text = e.clipboardData.getData('text')?.substring(0, 6)?.trim()

    if (!text) return

    text.split('').forEach((digit, index) => (placements.current[index] = digit))
    onChange(text)
  }

  return (
    <div className={cn(s.field, { [s.error]: !!error })}>
      <div className={s.inputs}>
        {dummyArray.map((_, index) => {
          return (
            <input
              key={index}
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              maxLength={6}
              value={typeof value === 'string' ? value.at(index) ?? '' : ''}
              onKeyUp={handleKeyUp}
              onChange={(e) => handleChange(e, index)}
              onPaste={handlePaste}
            />
          )
        })}
      </div>
      {error && (
        <p className={s.errorText}>
          {join([t('msg.verificationFailed', { ns: 'user' }), t('msg.plsTryAgain', { ns: 'common' })])}
        </p>
      )}
    </div>
  )
}

const TIMER_IN_SECONDS = 60

interface State {
  otp: string
  state: 'idle' | 'loading' | 'success' | 'error'
}

interface CommonProps {
  isOpen: boolean
  onClose: () => void
  successCallback: (data: any, trackEventData?: SuccessEventData) => void
  trackEventAttributes?: Record<string, string>
  ignorePageScroll?: boolean
}

interface PhoneModalProps extends CommonProps {
  variant?: 'phone-modal'
  dataToSend: {
    isdCode?: string
    contactNumber?: string
    customerId: string
  }
  trackEvent: TrackEventType
  onContinueWithoutPromo?: () => void
  hideContinueWithoutPromo?: boolean
}

interface EmailModalProps extends CommonProps {
  variant?: 'email-modal'
  onContinueWithoutPromo?: never
  phoneNumber?: never
  isdCode?: never
  dataToSend: Record<string, any> | null
  trackEvent?: never
  hideContinueWithoutPromo?: never
}

type OtpModalProps = PhoneModalProps | EmailModalProps

const initialState: State = {
  otp: '',
  state: 'idle',
}

const reducer = (state: State, newState: Partial<State>) => ({ ...state, ...newState })

const OtpModal: React.FC<OtpModalProps> = ({
  isOpen,
  onClose,
  dataToSend,
  successCallback,
  variant = 'email-modal',
  trackEvent,
  trackEventAttributes,
  onContinueWithoutPromo,
  hideContinueWithoutPromo,
  ignorePageScroll,
}) => {
  const { isMobileView } = useGlobalContext()
  const timeoutId = useRef<any>()
  const [remainingSeconds, setRemainingSeconds] = useState(0)
  const { t, tp } = useTranslation()
  const [state, updateState] = useReducer(reducer, initialState)
  const submittedOnce = useRef(false)
  const { toast } = useAppData()

  const [verifyOtp] = useMutation(VERIFY_OTP.mutation)
  const [resendOtp] = useMutation(RESEND_OTP.mutation)

  useEffect(() => {
    if (isOpen && variant === 'phone-modal') {
      trackEvent?.({
        attributeId: EVENTS.PHONE_VERIFICATION,
        attributeType: EVENTS.ATTRIBUTES_TYPE.POPUP,
        eventType: EVENTS.TYPE.EXPOSURE,
        attributeValue: {
          ...trackEventAttributes,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    submittedOnce.current = true
    updateState({ state: 'loading' })

    if (dataToSend === null) {
      updateState({ state: 'error' })
      return
    }

    try {
      const response = await verifyOtp({
        variables: { otp: state.otp, ...dataToSend },
      })

      const data = response.data[VERIFY_OTP.name]
      const error = getError(data)
      if (error?.code >= 400) {
        updateState({ state: 'error' })
        if (error?.code === RETRY_OTP_MAX_ATTEMPTS_REACHED) toast.error(error.message)
      } else {
        successCallback(
          { isdCode: dataToSend.isdCode, contactNumber: dataToSend.contactNumber, ...data },
          {
            keyName: 'loginType',
            keyValue: 'email',
            customerId: dataToSend.customerId,
            isPasswordLess: variant === 'email-modal' ? false : true,
          }
        )
        updateState(initialState)
        onClose()
      }

      if (variant === 'phone-modal') {
        trackEvent?.({
          attributeId: EVENTS.PHONE_VERIFICATION,
          attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
          eventType: EVENTS.TYPE.CLICK,
          attributeValue: {
            status: error.code >= 400 ? EVENTS.STATUS.FAILED : EVENTS.STATUS.SUCCESS,
            ...trackEventAttributes,
          },
        })
      }
    } catch (error: any) {
      logError(error)
      updateState({ state: 'error' })
    }
  }

  const onResendClick = async (e: any) => {
    e.preventDefault()
    const channel = e.target.getAttribute('data-channle') || 'WHATSAPP'

    updateState({ state: 'loading' })

    if (variant === 'phone-modal') {
      trackEvent?.({
        attributeId: EVENTS.RESEND_OTP,
        attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
        eventType: EVENTS.TYPE.CLICK,
        attributeValue: {
          channel: channel,
        },
      })
    }

    try {
      const response = await resendOtp({
        variables: { ...dataToSend, channel: channel },
      })

      const data = response.data[RESEND_OTP.name]
      const error = getError(data)
      if (error?.code >= 400) {
        updateState({ state: 'error' })
      } else {
        updateState(initialState)
      }
    } catch (error: any) {
      logError(error)
    } finally {
      setRemainingSeconds(TIMER_IN_SECONDS)
    }
  }

  const config = useMemo(() => {
    if (variant === 'email-modal') {
      return {
        title: t('verifyYourEmail', { ns: 'auth' }),
        description: tp('msg.enterDigitCodeSentToYourEmail', { ns: 'auth', digit: 6 }),
      }
    }

    return {
      title: t('verifyYourPhoneNumber', { ns: 'auth' }),
      description: tp('msg.enterDigitCodeSentToMobileNumber', {
        ns: 'auth',
        digit: 6,
        mobileNumber: dataToSend?.contactNumber,
      }),
    }
  }, [dataToSend?.contactNumber, t, tp, variant])

  useEffect(() => {
    if (remainingSeconds === 0) return

    timeoutId.current = setTimeout(() => {
      setRemainingSeconds((curr) => curr - 1)
    }, 1000)

    return () => clearTimeout(timeoutId.current)
  }, [remainingSeconds])

  useEffect(() => {
    if (isOpen) {
      setRemainingSeconds(TIMER_IN_SECONDS)
    }
  }, [isOpen])

  const verifyDisabled = useMemo(() => state.otp?.length !== 6, [state.otp])

  useEffect(() => {
    if (state.otp?.length !== 6 || submittedOnce.current) return

    handleSubmit(new Event('submit') as any)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.otp])

  const handleClose = () => {
    if (variant === 'phone-modal') {
      trackEvent?.({
        attributeId: EVENTS.PHONE_VERIFICATION_CLOSE,
        attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
        eventType: EVENTS.TYPE.CLICK,
        attributeValue: {
          ...trackEventAttributes,
        },
      })
    }
    onClose()
  }

  return (
    <DrawerDialog
      open={isOpen}
      onClose={handleClose}
      header={config?.title}
      center
      variant={isMobileView ? 'xsmall' : 'small'}
      autoMinHeight
      ignorePageScroll={ignorePageScroll}
      bringToFront
    >
      <p className={s.description}>{config?.description}</p>
      <OtpInput
        value={state.otp}
        error={state.state === 'error'}
        onChange={(value: any) => updateState({ otp: value })}
      />
      <Button
        loading={state.state === 'loading'}
        fluid
        size="medium"
        type="button"
        disabled={verifyDisabled || state.state === 'loading'}
        className={s.verify}
        onClick={handleSubmit}
      >
        {t('action.verify', { ns: 'common' })}
      </Button>
      {variant === 'email-modal' && (
        <>
          <p className={s.question}>{t('q.didntReceiveCode', { ns: 'auth' })}</p>
          <Button
            variant="link-tertiary"
            size="small"
            data-channle="EMAIL"
            disabled={remainingSeconds > 0 || state.state === 'loading'}
            onClick={onResendClick}
          >
            {remainingSeconds > 0
              ? t('msg.resendCodeInSecondsUnit', { ns: 'auth', seconds: remainingSeconds })
              : t('action.resendCode', { ns: 'auth' })}
          </Button>
        </>
      )}
      {variant === 'phone-modal' && (
        <>
          <p className={s.question}>
            {remainingSeconds > 0
              ? t('msg.resendCodeInSecondsUnit', { ns: 'auth', seconds: remainingSeconds })
              : t('msg.resendCodeTo', { ns: 'auth' })}
          </p>
          <Button
            variant="link-tertiary"
            size="small"
            disabled={remainingSeconds > 0 || state.state === 'loading'}
            data-channle="WHATSAPP"
            onClick={onResendClick}
          >
            {t('action.whatsapp', { ns: 'common' })}
          </Button>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <span className={s.ellipse}>•</span>
          <Button
            variant="link-tertiary"
            size="small"
            disabled={remainingSeconds > 0 || state.state === 'loading'}
            data-channle="PHONE"
            onClick={onResendClick}
          >
            {t('action.sms', { ns: 'common' })}
          </Button>
        </>
      )}
      {onContinueWithoutPromo && !hideContinueWithoutPromo && (
        <Button onClick={onContinueWithoutPromo} variant="link-tertiary" className={s.continueWithoutPromo}>
          {t('action.continueWithoutPromocode', { ns: 'booking' })}
        </Button>
      )}
    </DrawerDialog>
  )
}

export { OtpModal }
