import React, { useState } from 'react'
import { useRouter } from 'next/router'
import cn from 'classnames'

import { Icon } from 'ui/icon'
import { Button } from 'ui/button'

import { OtpModal } from 'components/otp-modal/verify'

import useTranslation from 'lib/hooks/useTranslation'
import useUserPreference from 'lib/hooks/useUserPreference'
import useAuth from 'lib/hooks/useAuth'
import { usePostSignupAction } from 'lib/hooks/usePostSignupAction'

import { matchPath } from 'lib/utils'
import { removeStorageItem, setStorageItem } from 'lib/utils/web-storage'
import { useAppData } from 'lib/context/app-data-context'

import { EVENTS } from 'lib/constants/events'
import { NEW_USER_CONSENT_MODAL } from 'lib/constants'

import { PrivacyPolicy } from '../privacy-policy'
import { SocialAuthButtons } from '../social-auth'
import { EmailButton } from '../email-button'
import { EmailForm } from './email-form'

import s from './styles.module.scss'
import common from '../styles.module.scss'

interface LoginProps {
  switchScreen: SwitchScreen
  setExistingSocialInfo: (data: ExistingSocialInfo) => void
  trackEvent: TrackEventType
  trackErrorEvent: TrackErrorEventType
  trackSuccessEvent: TrackSuccessEventType
}

const Login: React.FC<LoginProps> = ({
  switchScreen,
  setExistingSocialInfo,
  trackEvent,
  trackErrorEvent,
  trackSuccessEvent,
}) => {
  const router = useRouter()
  const { t } = useTranslation()
  const [showEmailForm, setShowEmailForm] = useState<boolean>(false)
  const [isOtpModalOpen, setIsOtpModalOpen] = useState<boolean>(false)
  const [responseData, setResponseData] = useState<UserResponse | null>(null)
  const [verifyData, setVerifyData] = useState<OtpVerifyCustomerData | null>(null)
  const { applyUserPreferences } = useUserPreference()
  const { handleUserResponse, getPostAuthRedirectUrl, authModal, consentModal } = useAuth()
  const { nextAction } = usePostSignupAction()
  const {
    partnerFeatureControl: { showFeature },
  } = useAppData()
  const isReferralEnable = showFeature('referralFeature')

  const onOtpModalClose = () => {
    if (responseData) {
      logInUser(responseData, {
        keyName: 'loginType',
        keyValue: 'email',
        customerId: responseData.customer?.customerId ?? '',
        isPasswordLess: true,
      })
    }
    setIsOtpModalOpen(false)
  }

  const logInEmailOnly = (data: OtpVerifyCustomerData) => {
    setIsOtpModalOpen(true)
    setVerifyData(data)
  }

  const logInEmailPassword = (data: UserResponse) => {
    const { customer } = data
    setResponseData(data)
    setVerifyData({
      email: customer?.email ?? '',
      customerId: customer?.customerId ?? '',
      login: false,
    })
    setIsOtpModalOpen(true)
  }

  const logInUser = async (data: UserResponse, trackEventData?: SuccessEventData) => {
    handleUserResponse(data)
    applyUserPreferences(data.customer as Customer)
    if (trackEventData) {
      trackSuccessEvent(
        EVENTS.USER_LOGGED_IN,
        trackEventData.keyName,
        trackEventData.keyValue,
        trackEventData.customerId,
        trackEventData.isPasswordLess
      )
    }

    const redirect = getPostAuthRedirectUrl()
    const isUserOnSameRedirectPage = matchPath(router.asPath, redirect)

    if (isReferralEnable) {
      if (!!verifyData?.created)
        nextAction({ action: 'marketing-consent', isReferral: false, isPageReload: true })
    } else {
      if (!!verifyData?.created) setStorageItem(NEW_USER_CONSENT_MODAL, true)
    }

    if (isUserOnSameRedirectPage) {
      window?.location?.reload()
    } else {
      const customerLocale = router.locales?.find(
        (l) => l.toLowerCase() === data.customer?.locale?.toLowerCase()
      )
      await router.replace(redirect, undefined, { locale: customerLocale })

      // to make sure chrome doenst get stuck
      if (customerLocale && customerLocale !== router.locale) {
        window?.location?.reload()
      } else if (!!verifyData?.created) {
        // since no page reload is happening, we can use app state to open the modal
        // do not have to rely on local storage
        removeStorageItem(NEW_USER_CONSENT_MODAL)
        if (isReferralEnable) {
          nextAction({ action: 'marketing-consent', isReferral: false, isPageReload: false })
        } else {
          consentModal.open({ isReferral: false })
        }
      }
      authModal.close()
    }
  }

  return (
    <div className={cn(s.login, common.screen)}>
      <div className={common.header}>
        {showEmailForm && (
          <button className={cn(common.backBtn, 'cursor-pointer')} onClick={() => setShowEmailForm(false)}>
            <Icon name="caret-left" color="#000" />
          </button>
        )}
        {/* this div is required as signup screen has svg on the right side */}
        <div>
          <h2 className={common.title}>{t('t.logIn', { ns: 'common' })}</h2>
          <p className={common.subtitle}>
            {t('exclm.continueExploringEnjoyRewardsAndMilesOnBooking', { ns: 'common' })}
          </p>
        </div>
      </div>

      <div className={common.content}>
        <div className={common.topContent}>
          {showEmailForm ? (
            <>
              <h3 className={common.continueWithEmail}>{t('action.continueWithEmail', { ns: 'auth' })}</h3>
              <EmailForm
                switchScreen={switchScreen}
                setExistingSocialInfo={setExistingSocialInfo}
                trackEvent={trackEvent}
                trackErrorEvent={trackErrorEvent}
                emailOnlySuccess={logInEmailOnly}
                emailWithPasswordSuccess={logInEmailPassword}
                logInUser={logInUser}
              />
            </>
          ) : (
            <>
              <SocialAuthButtons
                trackEvent={trackEvent}
                mode="login"
                iconPosition="right"
                buttonsLayoutClassName={cn(common.socialButtonsLayout)}
              />
              <EmailButton
                id="email-login-btn"
                iconPosition="right"
                customClassName={cn(common.emailButton)}
                onClick={() => {
                  setShowEmailForm(true)
                  trackEvent({
                    attributeId: EVENTS.LOGIN,
                    attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
                    attributeValue: {
                      loginType: 'email',
                    },
                  })
                }}
              >
                {t('action.continueWithEmail', { ns: 'auth' })}
              </EmailButton>
            </>
          )}
        </div>

        <div className={common.bottomContent}>
          <div className={common.authSwitcher}>
            <span>{`${t('q.dontHaveAnAccount', { ns: 'auth' })} `}</span>
            <Button size="small" variant="link-tertiary" onClick={() => switchScreen('signup')}>
              {t('t.signUp', { ns: 'common' })}
            </Button>
          </div>
          <PrivacyPolicy />
        </div>
      </div>
      <OtpModal
        dataToSend={verifyData}
        isOpen={isOtpModalOpen}
        onClose={onOtpModalClose}
        successCallback={logInUser}
      />
    </div>
  )
}

export { Login }
