import { Trans } from 'next-i18next'

import { ExternalLink } from 'components/external-link'

import useTranslation from 'lib/hooks/useTranslation'

import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from 'lib/constants'

import s from './styles.module.scss'

const PrivacyPolicy = () => {
  const { t, tsmart } = useTranslation()

  return (
    <p className={s.privacyPolicy}>
      <Trans
        ns="auth"
        i18nKey="msg.bySigningUpYouAgreeToOurTermAndPolicy"
        values={{
          term: tsmart('t.termsOfUse'),
          policy: tsmart('t.privacyPolicy'),
        }}
        components={{
          term: <ExternalLink className="tertiary-link" href={TERMS_OF_USE_LINK} />,
          policy: <ExternalLink className="tertiary-link" href={PRIVACY_POLICY_LINK} />,
        }}
      />
      {t('lang.punctuation')}
    </p>
  )
}

export { PrivacyPolicy }
