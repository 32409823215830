import React, { FC, useState } from 'react'
import cn from 'classnames'

import { Button } from 'ui/button'
import { Icon } from 'ui/icon'

import s from './styles.module.scss'

export type StatusType = 'warning' | 'error' | 'default' | 'info' | 'success'

type StatusProps = {
  type?: StatusType
  className?: string
  showHeader?: boolean
  headerText?: string
  expandable?: boolean
  defaultExpanded?: boolean | null
  ctaWithBG?: boolean
  twoColumns?: boolean
  button?: {
    text?: string
    variant?: 'link-tertiary' | 'tertiary'
  }
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const iconsMap: any = {
  default: 'cancellation',
  error: 'warning',
  warning: 'processing',
  info: 'information',
  success: 'success',
}

const headerMap: Record<string, string> = {
  default: 'Cancelled',
  error: 'Failed',
  warning: 'Processing',
  info: 'Information',
  success: 'Success',
}

const StatusMessage: FC<StatusProps> = ({
  children,
  type = 'default',
  className,
  showHeader,
  headerText,
  expandable,
  defaultExpanded = expandable ? false : null,
  button,
  ctaWithBG,
  twoColumns = false,
  onClick,
}) => {
  const [expand, toggleExpand] = useState<boolean | null>(defaultExpanded)

  return (
    <div className={cn(s.status, s[`${type}`], className)}>
      <div className={s.statusBuffer} />
      <div className={s.statusContainer}>
        <Icon name={iconsMap[type]} size="medium" className={s.statusIcon} />
        <div className={cn(s.statusMessage, { [s.twoColumns]: twoColumns })}>
          <div className={s.msgContainer}>
            {headerText ? (
              <div
                className={cn(s.statusHeader, {
                  [s.isCollapsed]: !expand,
                  [s.expandButton]: expandable,
                })}
                onClick={() => toggleExpand((isExpanded) => !isExpanded)}
              >
                {headerText}
                {expandable && <Icon name={expand ? 'caret-up' : 'caret-down'} size="medium" />}
              </div>
            ) : (
              showHeader && <div className={s.statusHeader}>{headerMap[type]}</div>
            )}
            {expandable ? expand && children : children}
          </div>
          {button?.text && (
            <div className={s.ctaContainer}>
              <Button
                variant={button.variant || 'link-tertiary'}
                size="small"
                onClick={onClick}
                className={cn({
                  [s.statusCta]: ctaWithBG,
                  [s.linkButton]: button.variant === 'link-tertiary',
                })}
              >
                {button.text}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export { StatusMessage }
