import React from 'react'

import { Button } from 'ui/button'

import { Link } from 'components/link'

import useTranslation from 'lib/hooks/useTranslation'

import { buildPath } from 'lib/utils'

import { FORGOT_PASSWORD_ROUTE, HOME_ROUTE } from 'lib/constants/routes'

import s from './styles.module.scss'

interface ComponentProps {
  onCloseModal: () => void
}

const Lockout: React.FC<ComponentProps> = ({ onCloseModal }) => {
  const { t, join } = useTranslation()

  return (
    <div className={s.lockout}>
      <h2 className={s.title}>{join([t('t.sorry'), t('msg.tooManyFailedAttempts')])}</h2>
      <p className={s.lockoutText}>
        {join([
          t('msg.weHaveDetectedMultipleFailedAttempts', { ns: 'auth' }),
          t('msg.forSecurityBlockingLoginAttempts', { ns: 'auth' }),
          t('msg.notToWorry'),
          t('msg.weCanHelpResetPassword', { ns: 'auth' }),
        ])}
      </p>
      <Link href={buildPath(FORGOT_PASSWORD_ROUTE)} passHref>
        <Button size="large" fluid onClick={onCloseModal}>
          {t('action.resetMyPassword', { ns: 'auth' })}
        </Button>
      </Link>
      <Link href={buildPath(HOME_ROUTE)} passHref>
        <Button variant="secondary" size="large" fluid onClick={onCloseModal}>
          {t('action.continueBrowsing')}
        </Button>
      </Link>
    </div>
  )
}

export { Lockout }
