import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import cn from 'classnames'
import { useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'

import { Input } from 'ui/text'
import { Button } from 'ui/button'

import { StatusMessage } from 'components/status-message'

import useAuth from 'lib/hooks/useAuth'
import useTranslation from 'lib/hooks/useTranslation'

import { logError, getError, aesEncrypt, buildPath, isBrowser } from 'lib/utils'
import { getDsUserId } from 'lib/utils/auth'
import { useAppData } from 'lib/context/app-data-context'
import { useFingerPrintContext } from 'lib/context/fingerprint-context'

import { FORGOT_PASSWORD_ROUTE } from 'lib/constants/routes'
import { EMAIL_REGEX, PASSWORD_MIN_LENGTH, LOGIN_PASSWORD_ENCRYPTION_KEY } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

import { LOGIN_MUTATION, CUSTOMER_ONBOARDING } from 'gql/auth'

import { getProviderFromText } from '../utils'

import s from './styles.module.scss'

type FormData = {
  email: string
  password?: string
}

interface EmailFormProps {
  emailOnlySuccess: (data: OtpVerifyCustomerData) => void
  emailWithPasswordSuccess: (data: UserResponse) => void
  switchScreen: SwitchScreen
  setExistingSocialInfo: (data: ExistingSocialInfo) => void
  trackEvent: TrackEventType
  trackErrorEvent: TrackErrorEventType
  logInUser: (data: UserResponse, trackEventData?: SuccessEventData) => void
}

const EmailForm: React.FC<EmailFormProps> = ({
  emailOnlySuccess,
  emailWithPasswordSuccess,
  switchScreen,
  setExistingSocialInfo,
  trackEvent,
  trackErrorEvent,
  logInUser,
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { setAuthInProgress, authModal } = useAuth()
  const { getRequestId: getFingerPrintRequestId } = useFingerPrintContext()
  const {
    partnerFeatureControl: { showFeature },
  } = useAppData()

  const [logInWithPassword, { loading: loginLoading }] = useMutation(LOGIN_MUTATION.mutation)
  const [logInWithEmail, { loading: onboardingLoading }] = useMutation(CUSTOMER_ONBOARDING.mutation)
  const { register, handleSubmit, formState, errors } = useForm<FormData>({ mode: 'onChange' })
  const [emailLoginError, setEmailLoginError] = useState<string>('')
  const [passwordLogin, setPasswordLogin] = useState<boolean>(false)

  const loading = loginLoading || onboardingLoading

  useEffect(() => {
    setAuthInProgress(loading)
  }, [loading, setAuthInProgress])

  const trackClickEvent = (loginType: string, isPasswordLess = false) => {
    trackEvent({
      attributeId: EVENTS.LOGIN,
      attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON,
      attributeValue: { loginType, isPasswordLess },
    })
  }

  const onSubmitEmailOnly = async ({ email }: FormData) => {
    trackClickEvent('email', true)

    let fingerPrintRequestId

    if (showFeature('fingerprint')) {
      try {
        fingerPrintRequestId = await getFingerPrintRequestId()
      } catch (e) {
        logError(e)
      }
    }

    try {
      const response = await logInWithEmail({
        variables: {
          email,
          dsUserId: getDsUserId(),
          fpRequestId: fingerPrintRequestId,
        },
      })

      const data = response.data[CUSTOMER_ONBOARDING.name]
      const error = getError(data)

      if (error?.code === 423) {
        // locked user
        switchScreen('lockout')
      } else if (error?.code === 409) {
        // email id matches social email
        const provider = getProviderFromText(error.errorMessage)
        setExistingSocialInfo({ email, provider })
        switchScreen('existingAccount')
        trackErrorEvent(EVENTS.USER_LOGGED_IN, 'loginType', 'email', error?.code, true)
      } else if (error?.code >= 400) {
        setEmailLoginError(error?.errorMessage)
        trackErrorEvent(EVENTS.USER_LOGGED_IN, 'loginType', 'email', error?.code, true)
      } else {
        emailOnlySuccess({
          email,
          customerId: data.additionalDetails?.customer_id,
          created: data.additionalDetails?.created,
          login: true,
        })
      }
    } catch (error: any) {
      logError(error)
      setEmailLoginError(t('msg.sorrySomethingWentWrong'))
      trackErrorEvent(EVENTS.USER_LOGGED_IN, 'loginType', 'email', error?.code, true)
    }
  }

  const onSubmitWithPassword = async ({ email, password }: FormData) => {
    trackClickEvent('email')

    let fingerPrintRequestId

    if (showFeature('fingerprint')) {
      try {
        fingerPrintRequestId = await getFingerPrintRequestId()
      } catch (e) {
        logError(e)
      }
    }

    try {
      const response = await logInWithPassword({
        variables: {
          email,
          password: aesEncrypt(password ?? '', LOGIN_PASSWORD_ENCRYPTION_KEY),
          fpRequestId: fingerPrintRequestId,
        },
      })

      const data = response.data[LOGIN_MUTATION.name]
      const error = getError(data)

      if (error?.code === 423) {
        // locked user
        switchScreen('lockout')
      } else if (error?.code === 409) {
        // email id matches social email
        const provider = getProviderFromText(error.errorMessage)
        setExistingSocialInfo({ email, provider })
        switchScreen('existingAccount')
        trackErrorEvent(EVENTS.USER_LOGGED_IN, 'loginType', 'email', error?.code, false)
      } else if (error?.code) {
        setEmailLoginError(error?.errorMessage)
        trackErrorEvent(EVENTS.USER_LOGGED_IN, 'loginType', 'email', error?.code, false)
      } else {
        if (!data.customer?.isEmailVerified) {
          emailWithPasswordSuccess(data)
        } else {
          logInUser(data, {
            keyName: 'loginType',
            keyValue: 'email',
            customerId: data.customer.customerId,
            isPasswordLess: false,
          })
        }
      }
    } catch (error: any) {
      logError(error)
      setEmailLoginError(t('msg.sorrySomethingWentWrong'))
      trackErrorEvent(EVENTS.USER_LOGGED_IN, 'loginType', 'email', error?.code, false)
    }
  }

  const submitHandler = ({ email, password }: FormData) => {
    if (passwordLogin) {
      onSubmitWithPassword({ email, password })
    } else {
      onSubmitEmailOnly({ email })
    }
  }

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className={s.grid}>
        <Input
          contClassName={s.customInput}
          name="email"
          type="email"
          noDescription={!errors.email?.message}
          placeholder={t('t.emailAddress', { ns: 'common' })}
          errorMessage={errors.email?.message}
          getRef={register({
            required: t('msg.thisFieldIsRequired', { ns: 'common' }) as string,
            pattern: {
              value: EMAIL_REGEX,
              message: t('msg.pleaseEnterValidEmail', { ns: 'auth' }),
            },
          })}
        />
        {passwordLogin && (
          <div>
            <div className={s.passwordWrapper}>
              <Input
                name="password"
                type="password"
                placeholder={t('password', { ns: 'auth' })}
                errorMessage={errors.password?.message}
                getRef={register({
                  required: t('msg.thisFieldIsRequired', { ns: 'common' }) as string,
                  minLength: PASSWORD_MIN_LENGTH,
                })}
                contClassName={cn(s.password, { [s.hasError]: errors.password?.message })}
                noDescription={!errors.password?.message}
              />
            </div>
            {isBrowser && (
              <Link href={{ pathname: buildPath(FORGOT_PASSWORD_ROUTE), query: router.query }}>
                <a
                  className={cn(s.forgotPassword, 'tertiary-link')}
                  onClick={() => {
                    trackEvent({
                      attributeId: EVENTS.FORGOT_PASSWORD,
                      attributeType: EVENTS.ATTRIBUTES_TYPE.LINK,
                    })
                    authModal.close()
                  }}
                >
                  {t('q.forgotYourPassword', { ns: 'auth' })}
                </a>
              </Link>
            )}
          </div>
        )}

        {emailLoginError && <StatusMessage type="error">{emailLoginError}</StatusMessage>}

        <div className={cn(s.submitButtons, { [s.passwordEnabled]: !!passwordLogin })}>
          <Button
            variant="primary"
            size="medium"
            id="login-btn"
            type="submit"
            disabled={loading || !formState.isValid}
            fluid
            className={s.cta}
          >
            {!passwordLogin
              ? t('action.sendLoginCode', { ns: 'auth' })
              : t('action.continueWithPassword', { ns: 'auth' })}
          </Button>

          <Button
            type="button"
            size="medium"
            fluid
            variant="secondary"
            disabled={loading}
            onClick={() => setPasswordLogin((s) => !s)}
          >
            {passwordLogin
              ? t('action.sendLoginCode', { ns: 'auth' })
              : t('action.continueWithPassword', { ns: 'auth' })}
          </Button>
        </div>
      </div>
    </form>
  )
}

export { EmailForm }
