import React from 'react'
import { useRouter } from 'next/router'

import { Button } from 'ui/button'

import { StatusMessage } from 'components/status-message'
import { Link } from 'components/link'

import { useSocialRedirect } from 'lib/hooks/socialAuth'
import useTranslation from 'lib/hooks/useTranslation'

import { buildPath } from 'lib/utils'

import { EVENTS } from 'lib/constants/events'
import { FORGOT_PASSWORD_ROUTE } from 'lib/constants/routes'

import { GoogleButton } from '../google-button'
import { FacebookButton } from '../facebook-button'

import common from '../styles.module.scss'
import s from './styles.module.scss'

const maskEmail = (email?: string) => {
  if (!email) return ''

  const [handle, domain] = email.split('@')
  const maskLength = Math.ceil(handle.length / 2)
  return `${handle[0] + '*'.repeat(maskLength) + handle.substr(handle.length - 2)}@${domain}`
}

interface ComponentProps {
  switchScreen: SwitchScreen
  socialInfo: ExistingSocialInfo
  trackEvent: TrackEventType
}

const ExistingAccount: React.FC<ComponentProps> = ({ socialInfo, switchScreen, trackEvent }) => {
  const { t, tp } = useTranslation('auth')

  const { goToSocialAuthUrl, error } = useSocialRedirect({ authScreen: 'login' })
  const { query } = useRouter()
  const { referralCode } = query

  return (
    <div className={s.existingAccount}>
      <h2 className={s.title}>{t('msg.thisAccountAlreadyExists', { ns: 'auth' })}</h2>

      {error && (
        <StatusMessage type="error" className={common.marginLess}>
          {error}
        </StatusMessage>
      )}

      <p className={common.subtitle}>
        {tp('msg.youHaveAlreadyCreatedAccWithProvider', { provider: socialInfo.provider, ns: 'auth' })}
      </p>
      <p className={s.email}>{maskEmail(socialInfo.email)}</p>

      {socialInfo.provider === 'FACEBOOK' && (
        <FacebookButton
          iconPosition="right"
          onClick={() => {
            trackEvent({ attributeId: EVENTS.FACEBOOK_LOGIN, attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON })
            goToSocialAuthUrl('FACEBOOK', { referralCode: referralCode as string })
          }}
        >
          {t('action.loginWithBrand', {
            ns: 'auth',
            brand: t('brand.facebook', { ns: 'auth' }),
          })}
        </FacebookButton>
      )}

      {socialInfo.provider === 'GOOGLE' && (
        <GoogleButton
          iconPosition="right"
          onClick={() => {
            trackEvent({ attributeId: EVENTS.GOOGLE_LOGIN, attributeType: EVENTS.ATTRIBUTES_TYPE.BUTTON })
            goToSocialAuthUrl('GOOGLE', { referralCode: referralCode as string })
          }}
        >
          {t('action.loginWithBrand', {
            ns: 'auth',
            brand: t('brand.google', { ns: 'auth' }),
          })}
        </GoogleButton>
      )}

      <Button variant="link-tertiary" onClick={() => switchScreen('login')} className="mt-2">
        {t('action.loginWithDifferentAccount', { ns: 'auth' })}
      </Button>
      <Link passHref href={buildPath(FORGOT_PASSWORD_ROUTE)}>
        <Button variant="link-tertiary">{t('q.forgotYourPassword', { ns: 'auth' })}</Button>
      </Link>
    </div>
  )
}

export { ExistingAccount }
