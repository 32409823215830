const MATCH_TO_PROVIDER: Record<string, OauthProvider> = {
  facebook: 'FACEBOOK',
  google: 'GOOGLE',
}

const getProviderFromText = (text: string): OauthProvider | null => {
  const match = text?.match(/facebook|google/i)

  if (!match || !match.length) return null
  const provider = MATCH_TO_PROVIDER[match[0].toLowerCase()]

  return provider
}

// @ts-ignore
export { getProviderFromText }
