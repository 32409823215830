import { useState, useEffect, FC } from 'react'

import { logError } from 'lib/utils'

interface ScriptProps {
  loaded: boolean
  error: boolean
}

interface WithScriptProps {
  src: string
  defer?: boolean
  async?: boolean
  render?: (props: ScriptProps) => any
  beforeLoaded?: () => void
  unmountOnExit?: boolean
}

const isTestEnv = process.env.NODE_ENV === 'test'

const WithScript: FC<WithScriptProps> = ({
  src,
  defer,
  async,
  render,
  beforeLoaded,
  unmountOnExit, // for script that needs to rerun for a specific page (like Captcha), this prop is to unmount/mount it when component is unmounted/mounted. Script like Google Map we don't need this behavior
}) => {
  const [loaded, setLoaded] = useState(isTestEnv)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (typeof beforeLoaded === 'function') beforeLoaded()
    if (document.getElementById(src)) setLoaded(true)
  }, [src, beforeLoaded])

  useEffect(() => {
    if (document.getElementById(src) || isTestEnv) return

    const script = document.createElement('script')
    script.src = src
    script.defer = Boolean(defer)
    script.async = Boolean(async)
    script.setAttribute('id', src)
    script.onload = () => {
      setLoaded(true)
    }
    script.onerror = () => {
      setError(true)
      logError(new Error(`Error loading ${src}`))
    }
    document.body.appendChild(script)

    if (unmountOnExit)
      return () => {
        document.body.removeChild(script)
      }
  }, [src, async, defer, unmountOnExit])

  return typeof render === 'function' ? render({ loaded, error }) : null
}

export { WithScript }
