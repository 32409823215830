import React from 'react'
import cn from 'classnames'

import GoogleIcon from 'brand-assets/illustrations/icons/google.svg'

import s from '../styles.module.scss'

const GoogleButton: React.FC<AuthButtonProps> = ({ 
  children,
  iconOnly,
  iconPosition = 'left',
  ...props
}) => (
  <button
    type="button"
    className={cn(s.authButton, { [s['icon-only']]: !!iconOnly }, 'cursor-pointer bold')}
    {...props}
  >
    {iconPosition !== 'right' && <GoogleIcon className={s.googleIcon} />}
    {!iconOnly && <span>{children}</span>}
    {iconPosition === 'right' && <GoogleIcon className={s.googleIcon} />}
  </button>
)

export { GoogleButton }
