import React from 'react'
import cn from 'classnames'

import FacebookIcon from 'brand-assets/illustrations/icons/facebook.svg'

import s from '../styles.module.scss'

const FacebookButton: React.FC<AuthButtonProps> = ({ 
  children,
  iconOnly,
  iconPosition = 'left',
  ...props
}) => (
  <button
    type="button"
    className={cn(s.authButton, { [s['icon-only']]: !!iconOnly }, 'cursor-pointer bold')}
    {...props}
  >
    {iconPosition !== 'right' && <FacebookIcon className={s.facebookIcon} />}
    {!iconOnly && <span>{children}</span>}
    {iconPosition === 'right' && <FacebookIcon className={s.facebookIcon} />}
  </button>
)

export { FacebookButton }
